import apiInstance from './index'
import queryParams from './queryParams'

export default {
  async getModules(paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`modules${query}`)
  },
  // Roles permission
  async getPermissionsByUser() {
    return await apiInstance.get('permissions/by-role')
  },
  async statusModule(data) {
    return await apiInstance.post('permissions/module', data)
  },
  async statusPermission(data) {
    return await apiInstance.post('permissions/status', data)
  },
  // Users permission
  async statusExceptionModule(data) {
    return await apiInstance.post('permissions/exception/module', data)
  },
  async statusExceptionPermission(data) {
    return await apiInstance.post('permissions/exception/status', data)
  },
}
