<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0 i-login">
        <!-- logo ostrail -->
        <div class="i-brand-logo">
          <light-vertical-ostrail-logo />
        </div>
        <!--/ logo ostrail -->

        <!-- Terms and conditions -->
        <div v-if="showConfirmAgreeToTerms">
          <terms-and-conditions
            :show-spinner-login="showSpinnerLogin"
            @assign-actions="assignActions"
          />
        </div>
        <!--/ Terms and conditions-->

        <!-- security code -->
        <div
          v-else-if="showConfirmValidation"
          class="mt-1"
        >
          <!-- text card -->
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('loginMessages.verifyYourEmail') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('loginMessages.sentAnEmail') }}
            <strong>"{{ formLogin.email }}"</strong>
            <br><br>
            {{ $t('loginMessages.needVerify') }} {{ $t('loginMessages.spamFolder') }}
            <br><br>
            {{ $t('loginMessages.clickTheResend') }}
          </b-card-text>
          <!--/ text card -->

          <!-- form validation -->
          <validation-observer ref="codeValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="validationCode"
            >
              <b-form-group
                :label="$t('loginMessages.securityCode')"
                label-for="sec-code"
              >
                <validation-provider
                  #default="{ errors }"
                  name="code"
                  rules="required"
                >
                  <b-form-input
                    id="sec-code"
                    v-model="secCode"
                    :state="errors.length > 0 ? false : null"
                    name="sec-code"
                    placeholder="34af2w"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                type="submit"
                variant="dark"
                block
                pill
                :disabled="showSpinnerLogin"
              >
                <b-spinner
                  v-if="showSpinnerLogin"
                  small
                />
                <span class="ml-spinner">{{ $t('loginMessages.continue') }}</span>
              </b-button>
            </b-form>
          </validation-observer>
          <!--/ form validation -->

          <!-- button -->
          <p class="text-center mt-2">
            <b-link @click="showMessage()">
              <i class="icon-0-icons-dark-repeat" /> {{ $t('loginMessages.resend') }}
            </b-link>
          </p>
          <!--/ button -->
          <br>
        </div>
        <!-- /security code -->

        <!-- login credentials -->
        <div v-else>
          <!-- local language -->
          <b-navbar-nav class="nav align-items-center ml-auto align-content-end mb-2">
            <locale :is-nav="false" />
          </b-navbar-nav>
          <!--/ local language -->

          <!-- text card -->
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ $t('loginMessages.loginWelcome') }} OStrail! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('loginMessages.loginSignIn') }}
          </b-card-text>
          <!--/ text card -->

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                :label="$t('message.tableHeader.email')"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="formLogin.email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@ostrail.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!--/ email -->

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">
                    {{ $t('loginMessages.password') }}
                  </label>
                  <b-link :to="{ name:'auth-forgot-password' }">
                    <small>{{ $t('loginMessages.forgotPassword') }}</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  vid="password"
                  rules="min:8|required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="formLogin.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!--/ forgot password -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="dark"
                block
                pill
                :disabled="invalid || showSpinnerLogin"
              >
                <b-spinner
                  v-if="showSpinnerLogin"
                  small
                />
                <span class="ml-spinner">{{ $t('loginMessages.signIn') }}</span>
              </b-button>
              <!--/ submit buttons -->
              <br>
            </b-form>
          </validation-observer>
        </div>
        <!--/ login credentials -->
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import axios from 'axios'
import store from '@/store/index'
/* eslint-disable global-require */
import {
  BCard, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup,
  BCardText, BCardTitle, BForm, BButton, VBTooltip, BNavbarNav, BSpinner,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, password, min } from '@validations'
import authService from '@/services/authService'
import permissionService from '@/services/permissionService'
import userService from '@/services/userService'
import LightVerticalOstrailLogo from '@/views/partials/LightVerticalOstrailLogo.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import TermsAndConditions from './TermsAndConditions.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },

  components: {
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    BNavbarNav,
    BSpinner,
    Locale,
    ValidationProvider,
    ValidationObserver,
    LightVerticalOstrailLogo,
    TermsAndConditions,
  },

  mixins: [togglePasswordVisibility],

  data() {
    return {
      secCode: null,
      userInfo: null,
      showSpinnerLogin: false,
      showConfirmAgreeToTerms: false,
      showConfirmValidation: false,
      permissionsList: [],
      formLogin: {
        password: '',
        email: '',
      },
      geolocation: {
        latitude: null,
        longitude: null,
        positionG: null,
        ip: null,
      },
      required, // validation rules
      email, // validation rules
      password, // validation rules
      min, // validation rules
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  mounted() {
    this.validatePosition(false)
  },

  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          setTimeout(() => {
            this.validatePosition(true)
          }, 500)

          this.getIpAddressClient()
        }
      })
    },

    validatePosition(pFlag) {
      if (this.geolocation.positionG == null) {
        (navigator.geolocation) ? navigator.geolocation.getCurrentPosition(this.getCoords, this.getStatus) : this.showDynamicMessage('loginMessages.sorry', 'loginMessages.geolocationIsNotSupported', 'warning')

        if (pFlag) {
          this.continueAction()
        }
      } else {
        this.continueAction()
      }
    },

    getCoords(pos) {
      this.geolocation.latitude = pos.coords.latitude
      this.geolocation.longitude = pos.coords.longitude
      this.geolocation.positionG = `https://maps.google.com/?q=${this.geolocation.latitude},${this.geolocation.longitude}`
    },

    getStatus(err) {
      let messageG = null

      switch (err.code) {
        case err.PERMISSION_DENIED:
          messageG = 'loginMessages.denied'
          break
        case err.POSITION_UNAVAILABLE:
          messageG = 'loginMessages.unavailable'
          break
        case err.TIMEOUT:
          messageG = 'loginMessages.timeOut'
          break
        default:
          messageG = 'loginMessages.unknown'
          break
      }
      this.showDynamicMessage('loginMessages.attention', messageG, 'warning')
    },

    continueAction() {
      this.showSpinnerLogin = true

      if (this.geolocation.ip != null && this.geolocation.latitude != null && this.geolocation.longitude != null) {
        authService.authenticate({
          username: this.formLogin.email,
          password: this.formLogin.password,
          current_locale: this.$i18n.locale,
        }).then(({ data }) => {
          localStorage.setItem('access_token', data.data.access_token)
          localStorage.setItem('refresh_token', data.data.refresh_token)
          this.setDataLocation()
        }).catch(error => {
          this.showSpinnerLogin = false
          this.responseCatch(error)
          this.$refs.loginForm.setErrors(error)
        })
      } else {
        this.showSpinnerLogin = false
        this.getIpAddressClient()
        this.showDynamicMessage('loginMessages.sorry', 'loginMessages.errorCurrentLocation', 'warning')
      }
    },

    async getIpAddressClient() {
      await axios.get('https://api.ipify.org?format=json').then(response => {
        this.geolocation.ip = response.data.ip
      }).catch(error => {
        console.error(error)
      })
    },

    setDataLocation() {
      userService.setUserLocation({
        current_ip: this.geolocation.ip,
        latitude: this.geolocation.latitude,
        longitude: this.geolocation.longitude,
      }).then(() => {
        this.getTerms()
      }).catch(error => {
        console.error(error.message)
      })
    },

    getTerms() {
      authService.getStatusOfTerms().then(response => {
        (response.data.data[0]) ? this.showConfirmValidation = true : this.showConfirmAgreeToTerms = true
        this.showSpinnerLogin = false
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    assignActions(pFlag) {
      if (pFlag) {
        this.showSpinnerLogin = true

        authService.agreeToTerms({ agree_to_terms: true }).then(() => {
          this.showConfirmValidation = true
          this.showConfirmAgreeToTerms = false
          this.showSpinnerLogin = false
        }).catch(error => {
          this.showSpinnerLogin = false
        })
      } else {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')

        this.formLogin = {
          password: '',
          email: '',
        }

        this.geolocation = {
          latitude: null,
          longitude: null,
          positionG: null,
          ip: null,
        }

        this.showConfirmAgreeToTerms = false
        this.showConfirmValidation = false
        this.userInfo = null
        this.secCode = null
      }
    },

    validationCode() {
      const regexCode = /^[a-zA-Z0-9\s]+$/

      if (regexCode.test(this.secCode) && this.secCode.length == 6) {
        this.$refs.codeValidation.validate().then(success => {
          if (success) {
            this.showSpinnerLogin = true

            authService.setValidationCode({ code: this.secCode }).then(() => {
              this.getUserInfo()
            }).catch(error => {
              this.showSpinnerLogin = false
              this.responseCatch(error)
            })
          } else this.showDynamicMessage('error-catch', 'loginMessages.invalidCode', 'error')
        })
      } else this.showDynamicMessage('error-catch', 'loginMessages.invalidCode', 'error')
    },

    getUserInfo() {
      const data = { locale: this.$i18n.locale }

      userService.getUserAuthenticated(data).then(response => {
        this.userInfo = response.data.data
        this.getPermissions()
      }).catch(error => {
        console.error(error)
      })
    },

    getPermissions() {
      permissionService.getPermissionsByUser().then(response => {
        this.permissionsList = response.data.data
        this.showSpinnerLogin = false
        this.assignData()
      }).catch(error => {
        console.error(error.message)
      })
    },

    assignData() {
      if (this.userInfo.clubs.length) {
        const sportClub = this.userInfo.clubs.filter(el => el.is_current)
        store.dispatch('setCurrentClub', sportClub[0])
      }

      store.dispatch('setInfo', { user: this.userInfo, permissions: this.permissionsList })

      this.$ability.update([{ action: 'manage', subject: 'all' }])

      setTimeout(() => {
        this.$router.replace(getHomeRouteForLoggedInUser()).then(() => {
          const welcomeMessage = store.state.userAuthStore.gender == 'Masculino' || store.state.userAuthStore.gender == 'Male' ? 'loginMessages.welcome' : 'loginMessages.welcomeA'

          this.$swal({
            title: `${this.$t(welcomeMessage)} ${this.userInfo.full_name}`,
            html: `${this.$t('loginMessages.messageLoginS1')} <b>${this.userInfo.role.name}</b>. <br> ${this.$t('loginMessages.messageLoginS2')}`,
            icon: 'success',
            confirmButtonText: this.$t('generic.accept'),
            confirmButtonColor: this.colorRGB,
          })
        })
      }, 200)
    },

    showMessage() {
      this.$swal({
        title: this.$t('loginMessages.verifyYourEmail'),
        html: `${this.$t('loginMessages.resendCode')} <b>"${this.formLogin.email}"</b>`,
        icon: 'success',
        confirmButtonText: this.$t('generic.accept'),
        confirmButtonColor: this.colorRGB,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
</style>
